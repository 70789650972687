import React from "react";

function Sygnet() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 103.5 88.75"
            xmlSpace="preserve"
        >
            <g fill="#FFF">
                <path d="M33.936 63.799L52.294 45.441 70.653 27.083 52.294 27.083 52.294 27.083 33.936 27.083 33.936 45.441z"></path>
                <path d="M66.142 57.772L66.141 57.772 66.141 57.772 47.783 76.131 66.141 76.131 66.141 76.129 84.5 76.129 84.5 57.772 84.5 57.772 84.5 39.414z"></path>
                <path d="M15.577 8.723H33.936V27.083H15.577z"></path>
            </g>
            <g fill="#334E9D">
                <path d="M33.935 27.082H52.294000000000004V45.441H33.935z"></path>
                <path
                    fillOpacity="0.7"
                    d="M52.294 27.083L52.294 45.441 70.653 27.083z"
                ></path>
                <path
                    fillOpacity="0.7"
                    d="M33.935 45.441L33.935 63.799 52.294 45.441z"
                ></path>
                <path d="M66.141 57.772H84.5V76.13H66.141z"></path>
                <path
                    fillOpacity="0.7"
                    d="M47.783 76.13L66.141 76.13 66.141 57.772z"
                ></path>
                <path
                    fillOpacity="0.7"
                    d="M66.141 57.772L84.5 57.772 84.5 39.415z"
                ></path>
                <path d="M15.577 8.724H33.936V27.083000000000002H15.577z"></path>
            </g>
        </svg>
    );
}

export default Sygnet;
