export const theme = {
    color: {
        white: '#FFF',
        lightGray: '#FAFAFA',
        gray: '#ECEBEE',
        blue: '#4040FF',
        black: '#000',
    },
    media: {
        phone: '@media screen and (max-width: 800px)',
        tablet: '@media screen and (max-width: 1240px)',
    }
};