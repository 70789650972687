import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    /* scroll-behavior: smooth; */
}

body {
    font-family: 'Poppins', sans-serif;
    color: #4C5458;
}

input, textarea {
    font-family: 'Poppins', sans-serif;
}

::-webkit-input-placeholder {
    text-align: center;
    transform: translateY(275%);
    font-weight: 500;
    color: #4C5458;
    font-size: 1.7rem;
}

:-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
}
::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
}
:-ms-input-placeholder {
    text-align: center;
}

*::-webkit-scrollbar {
      width: 22px;
      height: 18px;
      background: linear-gradient(0deg, #FAFAFA, #FAFAFA);
  }
  *::-webkit-scrollbar-thumb {

      height: 6px;
      border: 6px solid transparent;
      background-clip: padding-box;
      border-radius: 26px;
      background-color: rgb(224, 224, 224);

  }
  *::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
  }
  *::-webkit-scrollbar-corner {
      background-color: transparent;
  }

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

ul {
    list-style: none;
}

button {
    color: #4C5458;
    cursor: pointer;
    font-variant: small-caps;
    border: none;
    font-family: 'Poppins';
}

a {
    text-decoration: none;
    color: #4C5458;
}

//cookie

.cookie-container {
    background: white;
    padding: 1.8rem 0;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: animation .4s ease-out;
    animation-direction: alternate;
    ${({ theme }) => theme.media.phone} {
        flex-direction: column;
    }
}


.cookie-message {
    width: 80%;
    text-align: center;
    padding: 0 2rem;
    font-size: 1.5rem;
    ${({ theme }) => theme.media.phone} {
        font-size: 2rem;
        padding: 1rem 0;
    }
}

.cookie-button {
    margin: 0 2rem;
    font-size: 2rem;
    padding: 0.6rem 4rem 0.8rem 4rem;
    font-variant: small-caps;
    transition: background .3s ease-out;
    :hover {
        background:${({ theme }) => theme.color.blue};
        color: white;
    }
    ${({ theme }) => theme.media.phone} {
        font-size: 2.5rem;
        margin: 2rem 0;
    }
}

@keyframes animation {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);

    }
}

@media screen and (min-width: 2000px) {
  html {
    font-size: 84%;
  }
}

@media screen and (max-width: 1556px) {
    html {
      font-size: 52%;
    }

  }
  @media screen and (max-width: 800px) {
    html {
      font-size: 45%;
    }
}

`

export default GlobalStyle