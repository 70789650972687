import React from "react"
import styled from 'styled-components'
import GlobalStyle from '../components/GlobalStyle'
import Nav from '../components/StyledNav/Nav'
import Footer from '../components/Footer'

import { ThemeProvider } from 'styled-components'
import { theme } from '../utils/theme'
import CookieConsent from "react-cookie-consent";
const Layout = ({ children, path, myLocation }) => {
    return (
        <ThemeProvider theme={theme}>
            <>
                {/* <CookiesProvider cookies={cookies}>
                    <CookieBannerUniversal
                        message="Ta strona korzysta z plików cookie, aby poprawić komfort
                        użytkowania. Dalsze korzystanie ze strony oznacza, że zgadzasz się
                        na ich użycie."
                        cookie="user-has-accepted-cookies"
                        disableStyle={true}
                        buttonMessage="zgoda"
                        dismissOnScrollThreshold="false"
                    /> */}
                <CookieConsent
                    location="bottom"
                    buttonText="zgoda"
                    cookieName="cookie"
                    disableStyles="true"
                    containerClasses="cookie-container"
                    contentClasses="cookie-message"
                    buttonClasses="cookie-button"
                >
                    Ta strona korzysta z plików cookie, aby poprawić komfort
                    użytkowania. Dalsze korzystanie ze strony oznacza, że zgadzasz się
                    na ich użycie.{" "}
                </CookieConsent>

                <StyledLayout>
                    <GlobalStyle />
                    <Nav path={path} myLocation={myLocation} />
                    {children}
                </StyledLayout>
                <Footer />
                {/* </CookiesProvider> */}
            </>
        </ThemeProvider>
    )
}

const StyledLayout = styled.div`
position: relative;
z-index: 1;
background: ${({ theme }) => theme.color.white};
`

export default Layout