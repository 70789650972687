import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

//images
import Address from '../images/address.svg'
import Phone from '../images/phone.svg'
import Message from '../images/message.svg'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
// import Pencil from '../images/pencil.svg'

const Footer = () => {
    return (
        <StyledFooter>
            <StyledFooterBox>
                <StyledTitleBox>
                    <h2>Biuro Rachunkowe</h2>
                    <h3>studio art7 sp. z o. o.</h3>

                </StyledTitleBox>
                <StyledNIP>NIP: 7352510784 / KRS: 174946 / Regon: 492900290</StyledNIP>
                <StyledFooterLine />
                <StyledMainFooter>
                    <StyledContactInfo>
                        <StyledContactBox>
                            <img src={Address} alt="" />
                            <p>Jabłonka(34-480), ul. Sobieskiego 5</p>
                        </StyledContactBox>
                        <StyledContactBox>
                            <img src={Phone} alt="" />
                            <p>515 104 483</p>
                        </StyledContactBox>
                        <StyledContactBox>
                            <img src={Message} alt="" />
                            <p>biuro@art7.pl</p>
                        </StyledContactBox>
                    </StyledContactInfo>
                    <StyledSocialInfo>
                        <h2>Znajdź nas w mediach społecznościowych</h2>
                        <img src={Facebook} alt="" />
                        {/* <img src={Instagram} alt="" /> */}
                    </StyledSocialInfo>
                </StyledMainFooter>
                <StyledFooterMainLine />
                <StyledFooterCopyright>
                    <h3>copyright 2021 Art7</h3>
                    <Link as="a" to="/polityka-prywatnosci">Polityka Prywatności</Link>
                </StyledFooterCopyright>
            </StyledFooterBox>
        </StyledFooter>
    )
}

const StyledFooter = styled.div`
    background: #4C5458;
    color: ${({ theme }) => theme.color.white};
    position: sticky;
    bottom: 0;
    width: 100%;
`

const StyledTitleBox = styled.div`
    margin: 1rem 0;
    h3 {
        font-size: 2rem;
        font-weight: 300;
    }
`

const StyledNIP = styled.p`
    font-size: 2rem;
    font-weight: 400;
    margin: 2rem 0;
`

const StyledFooterBox = styled.div`
    width: 94%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 3rem 0;
    h2 {
        font-size: 2.4rem;
        font-weight: 400;
        /* margin: 3rem 0; */
    }
    ${({ theme }) => theme.media.phone} {
        width: 80%;
    }
`

const StyledFooterLine = styled.span`
    background: #6B6B6B;
    width: 100%;
    height: 1px;
`

const StyledMainFooter = styled.div`
    width: 100%;
    display: flex;
    padding: 2rem 0;
    ${({ theme }) => theme.media.phone} {
        flex-direction: column;
    }
`

const StyledContactInfo = styled.div`
    flex: 1;
`

const StyledContactBox = styled.div`
    display: flex;
    justify-content: flex-start;
    margin: 2rem 0;
    p {
        margin-left: 3rem;
        font-size: 2rem;
        font-weight: 400;
        ${({ theme }) => theme.media.phone} {
            margin-left: 1.6rem;
    }
    }
    img {
        width: 2.5rem;
    }
`

const StyledSocialInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    h2 {
        margin-right: 4rem;
    }
    img {
        width: 4.5rem;
        height: 4.5rem;
        margin: 0 1rem;
    }
`

const StyledFooterMainLine = styled.span`
    background: ${({ theme }) => theme.color.gray};
    width: 100%;
    height: 6px;
    border-radius: 9.5rem;
`

const StyledFooterCopyright = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3rem 0;
    h3 {
        font-size: 2rem;
        font-weight: 500;
        text-transform: uppercase;
    }
    a {
        color: ${({ theme }) => theme.color.white};
        font-size: 2rem;
        font-weight: 500;
    }
`

export default Footer
