import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Facebook from '../../images/facebook.svg'
import Instagram from '../../images/instagram.svg'
import Sygnet from '../../images/Sygnet'
const Burger = ({ path }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <StyledBurger as="button" open={open} onClick={() => setOpen(!open)}>
                <StyledSpan open={open} />
                <StyledSpan open={open} />
                <StyledSpan open={open} />
            </StyledBurger>
            <StyledNav path={path} open={open} >

                <Link to="/"><Sygnet /></Link>
                <ul>
                    {/* <li>
                        <Link to="/aktualnosci">Aktualności</Link>
                        {path === "/aktualnosci/" && <Line />}
                    </li> */}
                    <li>
                        <Link to="/o-nas">O nas</Link>
                        {path === "/o-nas/" && <Line />}
                    </li>
                    <li>
                        <Link to="/oferta">Oferta</Link>
                        {path === "/oferta/" && <Line />}
                    </li>
                    <li>
                        <Link to="/kontakt">Kontakt</Link>
                        {path === "/kontakt/" && <Line />}
                    </li>
                    {path === "/polityka-prywatnosci/" &&
                        <li>
                            <Link to="/polityka-prywatnosci">Polityka Prywatności</Link>
                            <Line />
                        </li>
                    }
                </ul>
                <StyledMedia>
                    <a href="https://www.facebook.com/BiurorachunkoweArt7/" target="_blank"><img src={Facebook} alt="facebook" /></a>
                    {/* <img src={Instagram} alt="instagram" /> */}
                </StyledMedia>
            </StyledNav>
        </>
    )
}

const StyledBurger = styled.div`
    width: 5rem;
    height: 5rem;
    position: fixed;
    background: transparent;
    top: 2%;
    right: 5%;
    z-index: 501;
    display: none;

    ${({ theme }) => theme.media.tablet} {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }
`

const StyledSpan = styled.span`
    width: 5rem;
    height: 0.625rem;
    background-color: #4C5458;
    border-radius: 10px;
    transform-origin: 1px;
    transition: transform 0.3s linear;
    &:nth-child(1) {
        transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
        transform: ${({ open }) => open ? 'scaleX(0%)' : 'scaleX(100%)'};
        opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
        transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
`

const StyledNav = styled.div`
    z-index: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    display: none;
    width: 100vw;
    height: 100%;
    transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(100%)'};
    transition: transform .5s ease;
    position: fixed;
    background: white;
    svg {
        width: 14rem;
    }
    ul {
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    li {
        font-weight: 500;
        cursor: pointer;
        font-size: 3.6rem;
        margin: 2rem 0;
        position: relative;
    }
    ${({ theme }) => theme.media.tablet} {
        display: flex;
    }
`

// const StyledLogo = styled.h1`
//     color: #4C5458;
//     font-family: Lobster;
//     font-size: 7rem;
//     font-weight: 400;
// `

const StyledMedia = styled.div`
    display: flex;
    align-items: center;
    img {
        margin: 0 1rem;
        cursor: pointer;
        width: 7rem;
    }
`
const Line = styled.span`
    height: 0.6rem;
    background: ${({ theme }) => theme.color.blue};
    width: 100%;
    position: absolute;
    bottom: -15%;
    left: 0%;
    border-radius: 3.5rem;
`

export default Burger
