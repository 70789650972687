import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import Burger from '../StyledNav/Burger'
import Facebook from '../../images/facebook.svg'
import Instagram from '../../images/instagram.svg'

import Sygnet from '../../images/Sygnet'

const Nav = ({ path, myLocation }) => {
    return (
        <>
            <StyledNav path={path} myLocation={myLocation}>
                {/* <Link to="/"><StyledLogo>art7</StyledLogo></Link> */}
                <Link to="/"><Sygnet /></Link>
                <ul>
                    {/* <li>
                        <Link to="/aktualnosci">Aktualności</Link>
                        {path === "/aktualnosci/" &&
                            <Line
                                transition={{ duration: 0.3 }}
                                initial={{ width: '0%' }}
                                animate={{ width: '100%' }} />
                        }
                    </li> */}
                    <li>
                        <Link to="/o-nas">O nas</Link>
                        {path === "/o-nas/" && <Line
                            transition={{ duration: 0.3 }}
                            initial={{ width: '0%' }}
                            animate={{ width: '100%' }} />}
                    </li>
                    <li>
                        <Link to="/oferta">Oferta</Link>
                        {path === "/oferta/" && <Line
                            transition={{ duration: 0.3 }}
                            initial={{ width: '0%' }}
                            animate={{ width: '100%' }} />}
                    </li>
                    <li>
                        <Link to="/kontakt">Kontakt</Link>
                        {path === "/kontakt/" && <Line
                            transition={{ duration: 0.3 }}
                            initial={{ width: '0%' }}
                            animate={{ width: '100%' }} />}
                    </li>

                    {path === "/polityka-prywatnosci/" &&
                        <li>
                            <Link to="/polityka-prywatnosci">Polityka Prywatności</Link>
                            {path === "/polityka-prywatnosci/" && <Line
                                transition={{ duration: 0.3 }}
                                initial={{ width: '0%' }}
                                animate={{ width: '100%' }} />}
                        </li>
                    }

                </ul>
                <StyledMedia>
                    <a href="https://www.facebook.com/BiurorachunkoweArt7/" target="_blank"><img src={Facebook} alt="facebook" /></a>
                    {/* <img src={Instagram} alt="instagram" /> */}
                </StyledMedia>
            </StyledNav>
            <Burger path={path} />
        </>
    )
}

const StyledNav = styled.div`
    min-height: 9vh;
    background: rgba(250, 250, 250, 0.93);
    position: ${({ myLocation }) => (myLocation === "/" ? "fixed" : "sticky")};
    /* position: fixed; */
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 15rem;
    z-index: 10;
    svg {
        width: 8rem;
    }
    ul {
        font-size: 2rem;
        display: flex;
        align-items: center;
    }
    li {
        position: relative;
        margin: 0 5rem;
        font-weight: 500;
        cursor: pointer;
        ::before {
            transition: all .3s ease;
            content: '';
            position: absolute;
            bottom: -20%;
            left: 0;
            height: 0.4rem;
            width: 0%;
            background: ${({ theme }) => theme.color.blue};
            border-radius: 3.5rem;
        }
        &:hover ::before {
            transform: translateX(-0.1%);
            width: 100%;
        }
    }
    ${({ theme }) => theme.media.tablet} {
        display: none;
    }
`
// const StyledLogo = styled.h1`
//     color: #4C5458;
//     font-family: Lobster;
//     font-size: 4rem;
//     font-weight: 400;
// `

const StyledMedia = styled.div`
    display: flex;
    align-items: center;
    img {
        margin: 0 .8rem;
        cursor: pointer;
        width: 4rem;
        transition: transform .2s ease;
        :nth-child(1) {
            filter: drop-shadow(0px 0px 4px #3B5998);
        }
        /* :nth-child(2) {
            filter: drop-shadow(0px 0px 4px #914ECE);
        } */
        :hover {
            transform: scale(1.1);
        }
    }
`
const Line = styled(motion.span)`
    height: 0.4rem;
    background: ${({ theme }) => theme.color.blue};
    width: 0%;
    position: absolute;
    bottom: -20%;
    left: 0%;
    border-radius: 3.5rem;
`

export default Nav
